<template>
  <div>
    <!-- <div>
      <loading :active.sync="isLoader" loader="spinner" color="#36304a" :width="70"></loading>
    </div>-->
    <div
      style="
        width: 100%;
        height: 100%;
        position: absolute;
        background: white;
        z-index: 1000;
      "
      v-if="isLoader"
    >
      <CircleLoader />
    </div>
    <div class="animated fadeIn" v-else>
      <div id="snackbar"></div>
      <b-card-header class="mb-3">
        <b-row>
          <b-col>
            <i class="icon-menu mr-1"></i>Schedules Check (Shows Compare)
          </b-col>
        </b-row>
      </b-card-header>

      <b-card>
        <div class="table_class">
          <!-- {{data}} -->
          <table class="table table-bordered w-100">
            <thead style="color: white">
              <tr>
                <th colspan="3"></th>
                <!-- {{wanasaCinemaStatusdetailsList}}<b-badge :variant="item_status.cinemaName=='online'?'success':'danger'"></b-badge> -->
                <th colspan="2">
                  Seef
                  <b-badge
                    :variant="
                      cinemaStatusSeef == 'Online' ? 'success' : 'danger'
                    "
                    >{{ cinemaStatusSeef }}</b-badge
                  >
                </th>
                <th colspan="2">
                  AL Hmara
                  <b-badge
                    :variant="
                      cinemaStatusAlhamra == 'Online' ? 'success' : 'danger'
                    "
                    >{{ cinemaStatusAlhamra }}</b-badge
                  >
                </th>
                <th colspan="2">
                  Wadi
                  <b-badge
                    :variant="
                      cinemaStatusWadi == 'Online' ? 'success' : 'danger'
                    "
                    >{{ cinemaStatusWadi }}</b-badge
                  >
                </th>
                <th colspan="2">
                  Oasis Juffair
                  <b-badge
                    :variant="
                      cinemaStatusOasis == 'Online' ? 'success' : 'danger'
                    "
                    >{{ cinemaStatusOasis }}</b-badge
                  >
                </th>
                 <th colspan="2">
                  Liwan
                  <b-badge
                    :variant="
                      cinemaStatusOasis == 'Online' ? 'success' : 'danger'
                    "
                    >{{ cinemaStatusOasis }}</b-badge
                  >
                </th>
              </tr>
              <tr>
                <th colspan="1">Movie Name</th>
                <th colspan="1">Rating Cineco</th>
                <th colspan="1">Rating Cinema BH</th>
                <th colspan="1">Cineco</th>
                <th colspan="1">Cinema BH</th>
                <th colspan="1">Cineco</th>
                <th colspan="1">Cinema BH</th>
                <th colspan="1">Cineco</th>
                <th colspan="1">Cinema BH</th>
                <th colspan="1">Cineco</th>
                <th colspan="1">Cinema BH</th>
                <th colspan="1">Wanasa Time</th>
                <th colspan="1">Cinema BH</th>
              </tr>
            </thead>

            <tbody v-if="data.length > 0">
              <tr v-for="(item, index) in data" :key="index">
                <!-- {{item}} -->
                <td>{{ item.WT_movie_name }}</td>
                <td
                  :style="
                    item.WT_Censor_Rating != item.CBH_Censor_Rating
                      ? 'background-color:#ffcccc;color:red;font-weight:bold;'
                      : ''
                  "
                >
                  {{ item.WT_Censor_Rating }}
                </td>
                <td
                  :style="
                    item.WT_Censor_Rating != item.CBH_Censor_Rating
                      ? 'background-color:#ffcccc;color:red;font-weight:bold;'
                      : ''
                  "
                >
                  {{ item.CBH_Censor_Rating }}
                </td>
                <td
                  :style="
                    item.WT_Seef != item.CBH_SEEF
                      ? 'background-color:#ffcccc;color:red;font-weight:bold;'
                      : ''
                  "
                >
                  <a
                    href="javascript:void(0)"
                    @click="checkWanasaTimeShowDeatials(item, 'Seef')"
                    >{{ item.WT_Seef }}</a
                  >
                </td>
                <td
                  :style="
                    item.WT_Seef != item.CBH_SEEF
                      ? 'background-color:#ffcccc;color:red;font-weight:bold;'
                      : ''
                  "
                >
                  <a
                    href="javascript:void(0)"
                    @click="checkCinecoDeatials(item, 'seef')"
                    >{{ item.CBH_SEEF }}</a
                  >
                </td>
                <td
                  :style="
                    item.WT_ALHamra != item.CBH_ALHamra
                      ? 'background-color:#ffcccc;color:red;font-weight:bold;'
                      : ''
                  "
                >
                  <a
                    href="javascript:void(0)"
                    @click="checkWanasaTimeShowDeatials(item, 'Hamra')"
                    >{{ item.WT_ALHamra }}</a
                  >
                </td>
                <td
                  :style="
                    item.WT_ALHamra != item.CBH_ALHamra
                      ? 'background-color:#ffcccc;color:red;font-weight:bold;'
                      : ''
                  "
                >
                  <a
                    href="javascript:void(0)"
                    @click="checkCinecoDeatials(item, 'alhamra')"
                    >{{ item.CBH_ALHamra }}</a
                  >
                </td>
                <td
                  :style="
                    item.WT_Wadi != item.CBH_Wadi
                      ? 'background-color:#ffcccc;color:red;font-weight:bold;'
                      : ''
                  "
                >
                  <a
                    href="javascript:void(0)"
                    @click="checkWanasaTimeShowDeatials(item, 'Wadi')"
                    >{{ item.WT_Wadi }}</a
                  >
                </td>
                <td
                  :style="
                    item.WT_Wadi != item.CBH_Wadi
                      ? 'background-color:#ffcccc;color:red;font-weight:bold;'
                      : ''
                  "
                >
                  <a
                    href="javascript:void(0)"
                    @click="checkCinecoDeatials(item, 'wadi')"
                    >{{ item.CBH_Wadi }}</a
                  >
                </td>
                <td
                  :style="
                    item.WT_juffair != item.CBH_juffair
                      ? 'background-color:#ffcccc;color:red;font-weight:bold;'
                      : ''
                  "
                >
                  <a
                    href="javascript:void(0)"
                    @click="checkWanasaTimeShowDeatials(item, 'Oasis')"
                    >{{ item.WT_juffair }}</a
                  >
                </td>
                <td
                  :style="
                    item.WT_juffair != item.CBH_juffair
                      ? 'background-color:#ffcccc;color:red;font-weight:bold;'
                      : ''
                  "
                >
                  <a
                    href="javascript:void(0)"
                    @click="checkCinecoDeatials(item, 'oasis')"
                    >{{ item.CBH_juffair }}</a
                  >
                </td>

                    <td
                  :style="
                    item.WT_LIW != item.CBH_LIW
                      ? 'background-color:#ffcccc;color:red;font-weight:bold;'
                      : ''
                  "
                >
                  <a
                    href="javascript:void(0)"
                    @click="checkWanasaTimeShowDeatials(item, 'liwan')"
                    >{{ item.WT_LIW }}</a
                  >
                </td>
                <td
                  :style="
                    item.WT_LIW != item.CBH_LIW
                      ? 'background-color:#ffcccc;color:red;font-weight:bold;'
                      : ''
                  "
                >
                  <a
                    href="javascript:void(0)"
                    @click="checkCinecoDeatials(item, 'liwan')"
                    >{{ item.CBH_LIW }}</a
                  >
                </td>
                <!-- {{item.WT_MovieID}}
                {{item.CBH_movie_id}}-->
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="11">
                  <center>Data Not Found!</center>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <b-modal
          id="cinecoDeatialsModal"
          ref="cinecoDeatialsModal"
          :title="'FULL DETAILS'"
          size="lg"
          no-close-on-esc
          no-close-on-backdrop
          hide-footer
        >
          <div class="row">
            <b-col sm="12 row">
              <b-col sm="3" class="text-right">
                <label for="from-date">
                  Movie Name :
                  <b class="text-danger">*</b>
                </label>
              </b-col>
              <b-col sm="9">{{ CinecoDeatials.CBH_movie_name }}</b-col>
            </b-col>
            <b-col sm="12 row mt-3">
              <b-col sm="3" class="text-right">
                <label for="from-date">
                  Cinema Name:
                  <b class="text-danger">*</b>
                </label>
              </b-col>
              <b-col sm="9">{{ CinecoDeatials.PCINEMA }}</b-col>
            </b-col>

            <b-col
              class="col-md-12 table-wrapper-scroll-y my-custom-scrollbar_xAxis"
            >
              <!-- {{CinecoDeatials}} -->
              <table class="table mb-0 table-bordered">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Schedule Key</th>
                    <!-- <th>Screen Name</th> -->
                    <th>Show date</th>
                    <th>Show Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(c_val, c_key) of singleCinecoDetails"
                    :key="c_key"
                  >
                    <td>{{ c_key + 1 }}</td>
                    <td>{{ c_val.ScheduleKey }}</td>
                    <!-- <td>{{c_val.ScheduleKey}}</td> -->
                    <td>{{ moment(c_val.ShowDate).format("DD-MM-YYYY") }}</td>
                    <td>
                      {{ moment(c_val.ShowTime).utc().format("hh:mm:ss A") }}
                    </td>
                    <!-- <td>
                      {{ c_val.FilmKey }}
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </b-col>
          </div>
          <hr />
          <b-button
            @click="hideCheckCinecoDeatials"
            variant="danger"
            class="pull-right ml-2 mt-2"
            >Close</b-button
          >
        </b-modal>

        <b-modal
          id="WanasaTimeShowDeatialsModal"
          ref="WanasaTimeShowDeatialsModal"
          :title="'FULL DETAILS'"
          size="lg"
          no-close-on-esc
          no-close-on-backdrop
          hide-footer
        >
          <div class="row">
            <b-col sm="12 row">
              <b-col sm="3" class="text-right">
                <label for="from-date">
                  Movie Name :
                  <b class="text-danger">*</b>
                </label>
              </b-col>
              <b-col sm="9">{{ singleMovieName }}</b-col>
            </b-col>
            <b-col sm="12 row mt-3">
              <b-col sm="3" class="text-right">
                <label for="from-date">
                  Cinema Name:
                  <b class="text-danger">*</b>
                </label>
              </b-col>
              <b-col sm="9">{{ singleCinemName }}</b-col>
            </b-col>

            <b-col
              class="col-md-12 table-wrapper-scroll-y my-custom-scrollbar_xAxis"
            >
              <!-- {{CinecoDeatials}} -->
              <table class="table mb-0 table-bordered">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Cineco Show ID</th>
                    <th>Screen Name</th>
                    <th>Show date</th>
                    <th>Show Time</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(c_val, c_key) of scheduleArray" :key="c_key">
                    <td>{{ c_key + 1 }}</td>
                    <td>{{ c_val.CinecoShowID }}</td>
                    <td>{{ c_val.screenname }}</td>
                    <td>{{ moment(c_val.ShowDate).format("DD-MM-YYYY") }}</td>
                    <td>{{ c_val.ShowTime }}</td>
                    <td>
                      <!-- <c-switch
                        class="mx-1"
                        color="danger"
                        variant="3d"
                        @change.native="changeStatus"
                      /> -->
                      <b-button
                        @click="changeStatusConfirm(c_val.ID)"
                        variant="danger"
                        size="sm"
                        >Disable Show</b-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </div>
          <hr />
          <b-button
            @click="hideCheckWanasaTimeShowDeatials"
            variant="danger"
            class="pull-right ml-2 mt-2"
            >Close</b-button
          >
        </b-modal>
        <b-modal
          id="DisableConfirmationOnSubmit"
          ref="DisableConfirmationOnSubmit"
          @ok="changeStatus"
          title="Schedule Disable Confirmation"
          class="center-footer"
          footer-class="justify-content-center border-0"
          cancel-variant="outline-danger"
          ok-variant="outline-success"
          centered
          hide-header
          no-close-on-esc
          no-close-on-backdrop
        >
          <div class="d-block text-center">
            <div>Are you sure you want to disable the show ?</div>
          </div>
        </b-modal>
        <hr />
      </b-card>
    </div>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import AccountReportService from "@/services/AccountReportService";
import SchedulesService from "@/services/SchedulesService";
import MasterService from "@/services/MasterService";
import axios from "axios";
// import HourGlassLoader from "./HourGlass.vue";
import CircleLoader from "./Circle.vue";
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";

// jspdf-autotable
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import { dashboardName, WANASHAdashboardName } from "@/helper/constant";
import XLSX from "xlsx";
import { pdfFillColor } from "@/helper/constant";
import Pagination from "vue-pagination-2";
import vSelect from "vue-select";

export default {
  name: "InternalAccountsReport",
  components: {
    Event,
    cSwitch,
    vSelect,
    Pagination,
    // HourGlassLoader,
    CircleLoader,
    // Loading
  },
  watch: {},
  data: function () {
    return {
      scheduleArray: [],
      singleCinecoDetails: [],
      singleMovieName: "",
      singleCinemName: "",
      CinecoDeatials: {},
      page: 1,
      perPage: 10,
      records: [],
      counter: 21,

      CinemaNameList: ["seef", "wadi", "alhamra", "oasis", "liwan"],
      wanasaCinemadetailsList: [],
      wanasaCinemaStatusdetailsList: [],
      cinemaStatusSeef: "erroe",
      cinemaStatusAlhamra: "erroe",
      cinemaStatusWadi: "erroe",
      cinemaStatusOasis: "erroe",
      cinemaStatusLiwan: "erroe",
      seefArr: [],
      wadiArr: [],
      alhamraArr: [],
      oasisArr: [],
      liwanArr: [],

      seefCinema: "",
      wadiCinema: "",
      alhamraCinema: "",
      oasisCinema: "",
      liwanCinema: "",
      selectedCinemaSchedule: null,

      data: [],
      NewArr: [],

      //Book New Transcation

      useVuex: false,
      theme: "bootstrap4",
      theme1: "bootstrap4",
      theme2: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
      isLoader: false,
      disableSchid: null,
    };
  },
  beforeMount() {
    let payload = {};

    if (this.secureStore.get("TTURO") == "Admin") {
      payload = {
        OrgID: 3,
      };
    }
    if (this.CinemaNameList.length > 0) {
      this.CinemaNameList.forEach((val, ind) => {
        this.getInternalAccountsReport(val);
      });
    }
    payload = {
      OrgID: 3,
    };
    this.$store.dispatch("common/setAllCinemas", payload);
  },
  mounted() {},
  methods: {
    changeStatusConfirm: function (ID) {
      this.disableSchid = ID;
      this.$refs["DisableConfirmationOnSubmit"].show();
    },
    changeStatus: function (ID) {
      let payload = {
        TableName: "CinemaSchedule",
        ID: this.disableSchid,
      };
      MasterService.activateOrDeactivate(payload)
        .then((response) => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
          this.getShowDetails(
            this.CinecoDeatials.FilmID,
            this.selectedCinemaSchedule
          );
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getShowDetails(FilmID, WT_CinemaID) {
      this.scheduleArray = [];
      let payload = {
        FilmID: FilmID,
        CinemaID: WT_CinemaID,
      };
      // console.log(payload);
      // return false;
      MasterService.getSheduleData(payload)
        .then((response) => {
          const { data } = response;
          if (data && data.Status) {
            this.scheduleArray = data.SessionData;
          } else {
            console.log("Please try again");
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    },
    moment: function (date) {
      return moment(date);
    },
    checkWanasaTimeShowDeatials(items, cinema) {
      this.selectedCinemaSchedule;
      this.singleCinecoDetails = [];
      this.singleCinemName = "";
      this.singleMovieName = "";
      console.log("WanasaTimeShowDeatials=>", items);

      this.CinecoDeatials = items;

      items.WT_cinemas.forEach((val, key) => {
        if (val.CinemaName.indexOf("Seef") >= 0 && cinema == "Seef") {
          console.log("WanasaTimeShowDeatials seef CinemaName=>", val);
          console.log(
            "WanasaTimeShowDeatials seef CinemaName=>",
            val.CinemaName
          );
          console.log("WanasaTimeShowDeatials seef=>", val.WT_movie_name);
          console.log("WanasaTimeShowDeatials seef Sessions=>", val.Sessions);
          console.log("WanasaTimeShowDeatials seef Shows=>", val.Shows);
          if (
            val.Shows != undefined ||
            val.Shows != "" ||
            val.Shows != null ||
            val.Shows != "undefined" ||
            val.Shows != "null"
          ) {
            this.singleCinecoDetails = val.Shows;
          }
          this.singleCinemName = val.CinemaName;

          this.selectedCinemaSchedule = val.CinemaID;
        }
        if (val.CinemaName.indexOf("Wadi") >= 0 && cinema == "Wadi") {
          console.log("WanasaTimeShowDeatials wadi=>", val.CinemaName);
          console.log("WanasaTimeShowDeatials wadi=>", val.WT_movie_name);
          console.log("WanasaTimeShowDeatials Wadi Sessions=>", val.Sessions);
          console.log("WanasaTimeShowDeatials Wadi Shows=>", val.Shows);
          if (
            val.Shows != undefined ||
            val.Shows != "" ||
            val.Shows != null ||
            val.Shows != "undefined" ||
            val.Shows != "null"
          ) {
            this.singleCinecoDetails = val.Shows;
          }
          this.singleCinemName = val.CinemaName;
          this.singleMovieName = val.WT_movie_name;
          this.selectedCinemaSchedule = val.CinemaID;
        }
        if (val.CinemaName.indexOf("Hamra") >= 0 && cinema == "Hamra") {
          console.log("WanasaTimeShowDeatials hamra=>", val.CinemaName);
          console.log("WanasaTimeShowDeatials hamra=>", val.WT_movie_name);
          console.log("WanasaTimeShowDeatials Hamra Sessions=>", val.Sessions);
          console.log("WanasaTimeShowDeatials Hamra Shows=>", val.Shows);
          if (
            val.Shows != undefined ||
            val.Shows != "" ||
            val.Shows != null ||
            val.Shows != "undefined" ||
            val.Shows != "null"
          ) {
            this.singleCinecoDetails = val.Shows;
          }
          this.singleCinemName = val.CinemaName;
          this.singleMovieName = val.WT_movie_name;
          this.selectedCinemaSchedule = val.CinemaID;
        }
        if (val.CinemaName.indexOf("Oasis") >= 0 && cinema == "Oasis") {
          console.log("WanasaTimeShowDeatials oasis=>", val.CinemaName);
          console.log("WanasaTimeShowDeatials oasis=>", val.WT_movie_name);
          console.log("WanasaTimeShowDeatials Oasis Sessions=>", val.Sessions);
          console.log("WanasaTimeShowDeatials Oasis Shows=>", val.Shows);
          if (
            val.Shows != undefined ||
            val.Shows != "" ||
            val.Shows != null ||
            val.Shows != "undefined" ||
            val.Shows != "null"
          ) {
            this.singleCinecoDetails = val.Shows;
          }
          this.singleCinemName = val.CinemaName;
          this.singleMovieName = val.WT_movie_name;
          this.selectedCinemaSchedule = val.CinemaID;
        }

          if (val.CinemaName.indexOf("Liwan") >= 0 && cinema == "Liwan") {
          console.log("WanasaTimeShowDeatials liwan=>", val.CinemaName);
          console.log("WanasaTimeShowDeatials liwan=>", val.WT_movie_name);
          console.log("WanasaTimeShowDeatials liwan Sessions=>", val.Sessions);
          console.log("WanasaTimeShowDeatials liwan Shows=>", val.Shows);
          if (
            val.Shows != undefined ||
            val.Shows != "" ||
            val.Shows != null ||
            val.Shows != "undefined" ||
            val.Shows != "null"
          ) {
            this.singleCinecoDetails = val.Shows;
          }
          this.singleCinemName = val.CinemaName;
          this.singleMovieName = val.WT_movie_name;
          this.selectedCinemaSchedule = val.CinemaID;
        }
      });
      // this.CinecoDeatials.PCINEMA = items.WT_cinemas;
      // this.singleCinecoDetails = ;
      this.singleMovieName = items.WT_movie_name;

      console.log(items, "items");

      this.getShowDetails(
        this.CinecoDeatials.FilmID,
        this.selectedCinemaSchedule
      );

      this.$refs["WanasaTimeShowDeatialsModal"].show();
    },
    hideCheckWanasaTimeShowDeatials() {
      this.singleCinecoDetails = [];
      this.singleCinemName = "";
      this.singleMovieName = "";
      this.$refs["WanasaTimeShowDeatialsModal"].hide();
    },
    checkCinecoDeatials(items, cinema) {
      // alert("Hellow");
      this.CinecoDeatials = items;
      this.CinecoDeatials.PCINEMA = cinema;
      this.$store.getters["common/getAllCinemas"].forEach((val, key) => {});
      this.getSingleCinecoModalDetails(items);
      console.log("CinecoDeatials=>", this.CinecoDeatials);
      this.$refs["cinecoDeatialsModal"].show();
    },
    hideCheckCinecoDeatials() {
      this.CinecoDeatials = "";
      this.$refs["cinecoDeatialsModal"].hide();
    },
    getSingleCinecoModalDetails(items) {
      console.log("Happy SIngh=>", items);
      let cinemaName = items.PCINEMA;
      // items.WT_CinemaName.indexOf("Hamra") >= 0
      //   ? "alhamra"
      //   : items.WT_CinemaName.indexOf("Seef") >= 0
      //   ? "seef"
      //   : items.WT_CinemaName.indexOf("Oasis") >= 0
      //   ? "oasis"
      //   : items.WT_CinemaName.indexOf("Wadi") >= 0
      //   ? "wadi"
      //   : 0;
      let payload = {
        cinema: cinemaName,
        filmKey: items.FilmID,
      };
      let options = {
        method: "GET",
        url: `https://api-v2-wanasatime.wanasatime.com/api/cineco/get-single-movie-schedule/${cinemaName}/${items.FilmID}`,
        data: JSON.stringify(payload),
        crossDomain: true,

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      axios(options)
        .then((result) => {
          const { statusText, status, data } = result;
          if (status == 200 && data) {
            console.log(data.data.recordset, "shgh");
            this.singleCinecoDetails = data.data.recordset;
            this.isLoader = false;
          }
        })
        .catch((error) => {});
    },
    ExportInternalAccountsExcel() {
      if (this.ExcelArr.length > 0) {
        var dataOne = XLSX.utils.json_to_sheet(this.ExcelArr);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataOne, "Internal_Accounts");
        XLSX.writeFile(
          wb,
          `${WANASHAdashboardName.toUpperCase()}_Internal_Accounts_Report_${new Date().toDateString()}.xlsx`
        );
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    getWanasaCinemadetailsList(cinemaNametype) {
      if (this.wanasaCinemadetailsList.length <= 0) {
        return new Promise((resolve) => {
          MasterService.getCinecoSession()
            .then((response) => {
              const SessionData = response.data.SessionData;

              if (this.wanasaCinemadetailsList.length != SessionData.length) {
                if (SessionData.length > 0) {
                  SessionData.forEach((s_val, s_key) => {
                    // let obj = {};
                    s_val["WT_movie_name"] = s_val.movie;
                    s_val["WT_Language"] = "";
                    s_val["WT_MovieID"] = s_val.TicketakID;
                    s_val["WT_Censor_Rating"] = s_val.Rating;
                    s_val["WT_TicketakID"] = s_val.TicketakID;
                    s_val["WT_ismap"] = s_val.ismap;
                    s_val["WT_cinemas"] = s_val.cinemas;

                    let cinemasList = s_val.cinemas;
                    s_val["WT_Seef"] = 0;
                    s_val["WT_ALHamra"] = 0;
                    s_val["WT_Wadi"] = 0;
                    s_val["WT_juffair"] = 0;
                    s_val["WT_LIW"] = 0;

                    s_val["CBH_SEEF"] = "0";
                    s_val["CBH_ALHamra"] = "0";
                    s_val["CBH_Wadi"] = "0";
                    s_val["CBH_juffair"] = "0";
                    s_val["CBH_LIW"] = "0";
                    s_val["CBH_movie_id"] = "0";
                    s_val["CBH_Censor_Rating"] = "0";
                    s_val["CBH_CinemaName"] = "";
                    s_val["CBH_CinemaID"] = "";
                    s_val["CBH_movie_name"] = "";
                    s_val["CBH_Language"] = "";
                    s_val["PCBHcinema"] = "";

                    if (cinemasList.length > 0) {
                      cinemasList.forEach((CVal, CKey) => {
                        if (CVal.CinemaName.indexOf("Seef") >= 0) {
                          s_val["WT_Seef"] = CVal.Sessions;
                          // s_val["CBH_CinemaName"] = CVal.CinemaName;
                          // s_val["CBH_CinemaID"] = CVal.CinemaID;
                          s_val["PCBHcinema"] = "seef";
                        }
                        if (CVal.CinemaName.indexOf("Wadi") >= 0) {
                          s_val["WT_Wadi"] = CVal.Sessions;
                          // s_val["CBH_CinemaName"] = CVal.CinemaName;
                          // s_val["CBH_CinemaID"] = CVal.CinemaID;
                          s_val["PCBHcinema"] = "wadi";
                        }
                        if (CVal.CinemaName.indexOf("Hamra") >= 0) {
                          s_val["WT_ALHamra"] = CVal.Sessions;
                          // s_val["CBH_CinemaName"] = CVal.CinemaName;
                          // s_val["CBH_CinemaID"] = CVal.CinemaID;
                          s_val["PCBHcinema"] = "alhamra";
                        }
                        if (CVal.CinemaName.indexOf("Oasis") >= 0) {
                          s_val["WT_juffair"] = CVal.Sessions;
                          // s_val["CBH_CinemaName"] = CVal.CinemaName;
                          // s_val["CBH_CinemaID"] = CVal.CinemaID;
                          s_val["PCBHcinema"] = "oasis";
                        }
                         if (CVal.CinemaName.indexOf("Liwan") >= 0) {
                          s_val["WT_LIW"] = CVal.Sessions;
                          // s_val["CBH_CinemaName"] = CVal.CinemaName;
                          // s_val["CBH_CinemaID"] = CVal.CinemaID;
                          s_val["PCBHcinema"] = "liwan";
                        }
                        s_val["WT_CinemaID"] = CVal.CinemaID;
                        s_val["WT_CinemaName"] = CVal.CinemaName;
                      });
                    }

                    this.wanasaCinemadetailsList.push(s_val);
                  });
                }
              }

              // this.wanasaCinemadetailsList = [];

              return resolve(this.wanasaCinemadetailsList);
            })
            .catch((error) => {
              console.log("Catch on Getting Event Error: ", error);
            });
        });
      }
    },
    getWanasaCinemaStatusdetailsList(cinemaNametype) {
      if (this.wanasaCinemaStatusdetailsList.length <= 0) {
        return new Promise((resolve) => {
          let obj = {
            cinemaNametype: cinemaNametype,
          };
          let options = {
            method: "GET",
            url: `https://api-v2-wanasatime.wanasatime.com/api/cineco/health-check/${cinemaNametype}`,
            data: JSON.stringify(obj),
            crossDomain: true,

            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          };

          axios(options)
            .then((result) => {
              const { statusText, status, data } = result;
              if (status == 200 && data) {
                if (cinemaNametype == "seef") {
                  this.cinemaStatusSeef =
                    data.status == "success" ? "Online" : "Offline";
                }
                if (cinemaNametype == "seef") {
                  this.cinemaStatusAlhamra =
                    data.status == "success" ? "Online" : "Offline";
                }
                if (cinemaNametype == "seef") {
                  this.cinemaStatusWadi =
                    data.status == "success" ? "Online" : "Offline";
                }
                if (cinemaNametype == "seef") {
                  this.cinemaStatusOasis =
                    data.status == "success" ? "Online" : "Offline";
                }
                 if (cinemaNametype == "liwan") {
                  this.cinemaStatusOasis =
                    data.status == "success" ? "Online" : "Offline";
                }
                let Cinema_Obj = {
                  cinemaName: cinemaNametype,
                  cinemaStatus: data.status == "success" ? "Online" : "Offline",
                };
                this.wanasaCinemaStatusdetailsList.push(Cinema_Obj);
                this.isLoader = false;
              }
            })
            .catch((error) => {});
          return resolve(this.wanasaCinemaStatusdetailsList);
        });
      }
    },
    async getInternalAccountsReport(cinemaNametype) {
      let checkValueExit = [];
      let checkValueStatusExit = [];
      this.isLoader = true;
      if (this.wanasaCinemadetailsList.length <= 0) {
        this.wanasaCinemadetailsList = [];
        this.wanasaCinemaStatusdetailsList = [];
        checkValueExit = await this.getWanasaCinemadetailsList(cinemaNametype);
        checkValueStatusExit = await this.getWanasaCinemaStatusdetailsList(
          cinemaNametype
        );
        this.wanasaCinemadetailsList = checkValueExit;
        this.wanasaCinemaStatusdetailsList = checkValueStatusExit;

        // console.log(
        //   "wanasaCinemadetailsList unique=>",
        //   this.wanasaCinemadetailsList
        // );
        // console.log(
        //   "wanasaCinemaStatusdetailsList unique=>",
        //   this.wanasaCinemaStatusdetailsList
        // );
        //  const unique = [
        //             ...new Map(
        //               this.wanasaCinemadetailsList.map((item) => [
        //                 item["movie_name"],
        //                 item,
        //               ])
        //             ).values(),
        //           ];
        //           this.wanasaCinemadetailsList = unique;
      }
      if (checkValueExit.length > 0) {
        let obj = {
          cinema: cinemaNametype,
        };

        let options = {
          method: "POST",
          url: `https://api-v2-wanasatime.wanasatime.com/api/cineco/get-node-stats`,
          data: JSON.stringify(obj),
          crossDomain: true,

          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };

        axios(options)
          .then((result) => {
            const { statusText, status, data } = result;
            // console.log("Happy Singh=>", result);
            console.log(data.cinema, "=>", data.data.recordset);
            if (status == 200 && data) {
              if (data.cinema == "seef") {
                (this.seefCinema = data.cinema),
                  (this.seefArr = data.data.recordset);
                if (
                  this.wanasaCinemadetailsList.length > 0 &&
                  this.seefArr.length > 0
                ) {
                  this.wanasaCinemadetailsList.forEach((Wval, Wkey) => {
                    this.seefArr.forEach((Sval, Skey) => {
                      if (Sval.FilmName == Wval.WT_movie_name) {
                        Wval["CBH_movie_id"] = Sval.FilmKey;
                        Wval["CBH_movie_name"] = Sval.FilmName;
                        Wval["CBH_Language"] = Sval.FLanguage;
                        Wval["CBH_SEEF"] = Sval.TotalActiveSchedules;
                        Wval["CBH_Censor_Rating"] = Sval.RDesc;
                        Wval["CBH_CinemaName"] = data.cinema;
                        // Wval["CBH_CinemaName"] = data.cinema ? data.cinema : 0;
                        Wval["CBH_CinemaID"] = Wval.CinemaID
                          ? Wval.CinemaID
                          : 0;
                        // Wval["PCBHcinema"] = data.cinema;
                        // Wval["PCBHcinema"] = this.seefCinema;
                      }
                    });
                    this.NewArr.push(Wval);
                  });
                }
              } else if (data.cinema == "wadi") {
                (this.wadiCinema = data.cinema),
                  (this.wadiArr = data.data.recordset);
                if (
                  this.wanasaCinemadetailsList.length > 0 &&
                  this.wadiArr.length > 0
                ) {
                  this.wanasaCinemadetailsList.forEach((Wval, Wkey) => {
                    this.wadiArr.forEach((WAval, WAkey) => {
                      // Wval["PCBHcinema"] = data.cinema;
                      if (WAval.FilmName == Wval.WT_movie_name) {
                        Wval["CBH_movie_id"] = WAval.FilmKey;
                        Wval["CBH_movie_name"] = WAval.FilmName;
                        Wval["CBH_Language"] = WAval.FLanguage;
                        Wval["CBH_Wadi"] = WAval.TotalActiveSchedules;
                        Wval["CBH_Censor_Rating"] = WAval.RDesc;
                        Wval["CBH_CinemaName"] = data.cinema;
                        // Wval["CBH_CinemaName"] = data.cinema ? data.cinema : 0;
                        Wval["CBH_CinemaID"] = Wval.CinemaID
                          ? Wval.CinemaID
                          : 0;
                        // Wval["PCBHcinema"] = data.cinema;
                        // Wval["PCBHcinema"] = this.wadiCinema;
                      }
                    });
                    this.NewArr.push(Wval);
                  });
                }
              } else if (data.cinema == "alhamra") {
                (this.alhamraCinema = data.cinema),
                  (this.alhamraArr = data.data.recordset);
                if (
                  this.wanasaCinemadetailsList.length > 0 &&
                  this.alhamraArr.length > 0
                ) {
                  this.wanasaCinemadetailsList.forEach((Wval, Wkey) => {
                    this.alhamraArr.forEach((AHDval, AHkey) => {
                      // Wval["PCBHcinema"] = data.cinema;
                      if (AHDval.FilmName == Wval.WT_movie_name) {
                        // if (AHval.FilmName == Wval.WT_movie_name) {
                        //  alert("ALHA",AHDval.FilmName)
                        // alert("WANASH",Wval.WT_movie_name)
                        // console.log("AHDval=>",AHDval)
                        // console.log("Wval=>",Wval)
                        Wval["CBH_movie_id"] = AHDval.FilmKey;
                        Wval["CBH_movie_name"] = AHDval.FilmName;
                        Wval["CBH_Language"] = AHDval.FLanguage;
                        Wval["CBH_ALHamra"] = AHDval.TotalActiveSchedules;
                        Wval["CBH_Censor_Rating"] = AHDval.RDesc;
                        Wval["CBH_CinemaName"] = data.cinema;
                        // Wval["CBH_CinemaName"] = data.cinema ? data.cinema : 0;
                        Wval["CBH_CinemaID"] = Wval.CinemaID
                          ? Wval.CinemaID
                          : 0;
                        // Wval["PCBHcinema"] = data.cinema;
                        // Wval["PCBHcinema"] = this.alhamraArr;
                      }
                    });
                    this.NewArr.push(Wval);
                    // console.log(data.cinema,"Priyanshu NewArr=>",NewArr)
                  });
                }
              } else if (data.cinema == "oasis") {
                (this.oasisCinema = data.cinema),
                  (this.oasisArr = data.data.recordset);
                if (
                  this.wanasaCinemadetailsList.length > 0 &&
                  this.oasisArr.length > 0
                ) {
                  this.wanasaCinemadetailsList.forEach((Wval, Wkey) => {
                    this.oasisArr.forEach((OJval, OJkey) => {
                      // Wval["PCBHcinema"] = data.cinema;
                      if (OJval.FilmName == Wval.WT_movie_name) {
                        Wval["CBH_movie_id"] = OJval.FilmKey;
                        Wval["CBH_movie_name"] = OJval.FilmName;
                        Wval["CBH_Language"] = OJval.FLanguage;
                        Wval["CBH_juffair"] = OJval.TotalActiveSchedules;
                        Wval["CBH_Censor_Rating"] = OJval.RDesc;
                        Wval["CBH_CinemaName"] = data.cinema;
                        // Wval["CBH_CinemaName"] = data.cinema ? data.cinema : 0;
                        Wval["CBH_CinemaID"] = Wval.CinemaID
                          ? Wval.CinemaID
                          : 0;
                        // Wval["PCBHcinema"] = data.cinema;
                      }
                    });
                    // Wval["PCBHcinema"] = this.oasisCinema;
                    this.NewArr.push(Wval);
                  });
                }
              } else if (data.cinema == "liwan") {
                (this.liwanCinema = data.cinema),
                  (this.liwanArr = data.data.recordset);
                if (
                  this.wanasaCinemadetailsList.length > 0 &&
                  this.liwanArr.length > 0
                ) {
                  this.wanasaCinemadetailsList.forEach((Wval, Wkey) => {
                    this.liwanArr.forEach((LJval, LJkey) => {
                      // Wval["PCBHcinema"] = data.cinema;
                      if (LJval.FilmName == Wval.WT_movie_name) {
                        Wval["CBH_movie_id"] = LJval.FilmKey;
                        Wval["CBH_movie_name"] = LJval.FilmName;
                        Wval["CBH_Language"] = LJval.FLanguage;
                        Wval["CBH_LIW"] = LJval.TotalActiveSchedules;
                        Wval["CBH_Censor_Rating"] = LJval.RDesc;
                        Wval["CBH_CinemaName"] = data.cinema;
                        // Wval["CBH_CinemaName"] = data.cinema ? data.cinema : 0;
                        Wval["CBH_CinemaID"] = Wval.CinemaID
                          ? Wval.CinemaID
                          : 0;
                        // Wval["PCBHcinema"] = data.cinema;
                      }
                    });
                    // Wval["PCBHcinema"] = this.oasisCinema;
                    this.NewArr.push(Wval);
                  });
                }
              }
              this.isLoader = false;
            }

            console.log("seefArr", this.seefArr);
            console.log("wadiArr", this.wadiArr);
            console.log("alhamraArr", this.alhamraArr);
            console.log("oasisArr", this.oasisArr);
            console.log("liwanArr", this.liwanArr);
            const unique = [
              ...new Map(
                this.NewArr.map((item) => [item["WT_movie_name"], item])
              ).values(),
            ];
            this.data = unique;
            console.log("data", this.data);
            this.data.forEach((d_val, d_key) => {
              d_val["NPCBHcinema"] = "";
              if (d_val.WT_Seef == d_val.CBH_SEEF) {
                d_val["NPCBHcinema"] = "seef";
              }
              if (d_val.WT_ALHamra == d_val.CBH_ALHamra) {
                d_val["NPCBHcinema"] = "alhamra";
              }
              if (d_val.WT_Wadi == d_val.CBH_Wadi) {
                d_val["NPCBHcinema"] = "oasis";
              }
              if (d_val.WT_juffair == d_val.CBH_juffair) {
                d_val["NPCBHcinema"] = "wadi";
              }
               if (d_val.WT_LIW == d_val.CBH_LIW) {
                d_val["NPCBHcinema"] = "liwan";
              }
            });
            console.log("NewArr", this.NewArr);
          })
          .catch((error) => {});
      }
    },

    // Show Error Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
  computed: {},
  created() {},
};
</script>

<style>
label {
  font-weight: 600;
}
.table_class {
  overflow: auto;
}
td {
  padding: 0px;
}
.table-bordered thead th {
  border: 1px solid white;
}
.pagination_button {
  padding: 8px;
  margin: 2px;
  border-radius: 3px;
  font-size: 1em;
  cursor: pointer;
}
.pagination-row {
  padding: 5px 0;
}

.my-custom-scrollbar {
  position: relative;
  height: 850px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
